.flexslider {
    background-color: transparent;
    border: none;
    margin: 0 auto;
    .flex-caption {
        font-size: 10px;
        margin: 1em 1em 0;
        text-align: center;
        text-transform: uppercase;
    }
    .flex-control-nav {
        a {
            background: #ffffff;
        }
        .flex-active {
            background: #000000;
        }
    }
    .flex-direction-nav {
        // .flex-nav-prev,
        // .flex-nav-next {}
        .flex-nav-prev {
            padding-left: 20px;
        }
        .flex-nav-next {
            padding-right: 20px;
        }
        a {
            padding: 12px 25%;
            position: absolute;
            top: calc(50% - 24px);
            margin: 0;
            z-index: 10;
            color: rgba(0, 0, 0, 0) !important;
            text-shadow: none !important;
            text-indent: -1000px;
            overflow: hidden;
            display: block;
            font-size: 100px;
            width: 0;
            height: 0;
            &::before {
                display: none;
            }
        }
        .flex-prev {
            background: transparent url("../images/icon/flex-prev.png?20170402") no-repeat center left;
            left: 0;
        }
        .flex-next {
            background: transparent url("../images/icon/flex-next.png?20170402") no-repeat center right;
            right: 0;
        }
    }
    img {
        height: auto;
        margin: 0 auto;
        max-height: 90vh !important;
        width: auto !important;
        @media screen and (max-width: $screen-xs - 1) {
            max-width: 67vw;
        }
        @media screen and (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
            max-width: $screen-xs - 50;
        }
        @media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
            max-width: $screen-sm - 50;
        }
        @media screen and (min-width: $screen-md) and (max-width: $screen-lg - 1) {
            max-width: $screen-md - 50;
        }
        @media screen and (min-width: $screen-lg) {
            max-width: 100%;
        }
    }
}