.masonry-item {
    border : none;
    margin : 0 0 12px;
    padding: 0;
    width  : 320px;
    @media screen and (max-width: $screen-xs - 1) {
        width: 270px;
    }
    @media screen and (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
        width: 300px;
    }
    @media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
        width: 306px;
    }
    @media screen and (min-width: $screen-md) and (max-width: $screen-lg - 1) {
        width: 500px;
    }
    @media screen and (min-width: $screen-lg) {
        width: 360px;
    }

    a {
        display: block;
    }
}

.node-teaser {
    position: relative;

    img {
        max-width: 100%;
        width    : auto;
        height   : auto;
    }

    .field-title-wrapper {
        display: none;
        height : 100%;
    }

    .story-title {
        color         : $story-title-color;
        margin        : 0;
        padding       : 0;
        text-align    : center;
        text-transform: uppercase;

        a {
            align-items     : center;
            background-color: rgba(255,255,255,0.70);
            color           : $story-title-color !important;
            flex-direction  : column;
            font-size       : 10px;
            height          : 100%;
            justify-content : center;
            left            : 0;
            position        : absolute;
            text-decoration : none !important;
            text-transform  : uppercase;
            top             : 0;
            width           : 100%;
            z-index         : 100;
        }
    }

    &:hover {
        .field-title-wrapper {
            display: block;
        }

        .story-title {
            a {
                display: flex;
            }
        }
    }
}

.view-overview {
    vertical-align: top;

    .view-content {
        width : 85vw;
        margin: 0 auto;
    }
}
