// float site name & slogan in center on top

.site-header {
    margin  : 20px 0;
    position: absolute;
    width   : 100%;
    z-index : 0;
}

.site-name {
    letter-spacing: 6px;
    line-height   : 46px;
    margin        : 0;
    text-align    : center;
    text-transform: uppercase;
    @media screen and (max-width: $screen-xs - 1) {
        line-height   : 24px;
        letter-spacing: 2px;
    }
}

.site-slogan {
    font-size     : 14px !important;
    letter-spacing: 4px;
    margin        : 0;
    text-align    : center;
    text-transform: uppercase;
}
