// Basic menu blocks
// -----------------------------------------------------------------------------
ul.menu > li > ul.menu {
    margin-left: 1em;
    /* LTR */
}

// Nav
// -----------------------------------------------------------------------------
ul.nav {
    > li {
        &.dropdown {
            &:after {
                width     : 100%;
                height    : 2px;
                background: none;
                position  : absolute;
                bottom    : -2px;
                left      : 0;
                right     : 0;
                content   : '';
            }
        }
    }
}

// Nav expanded
// -----------------------------------------------------------------------------
.nav-expanded {
    .caret {
        display: none;
    }

    > li {
        > a {
            font-size  : 1.2em;
            text-align : left;
            font-weight: 700;
            color      : $text-color;

            &.active,
            &.active-trail,
            &:focus,
            &:hover {
                color     : $link-hover-color;
                background: none;
            }
        }

        ul.dropdown-menu {
            background   : none;
            display      : block;
            border       : 0;
            padding      : 0;
            position     : static;
            float        : none;
            border-radius: 0;
            box-shadow   : none;

            li {
                a {
                    padding: 3px 15px;
                    color  : $text-color;

                    &.active,
                    &.active-trail,
                    &:focus,
                    &:hover {
                        color     : $link-hover-color;
                        background: none;
                    }
                }
            }

            ul {
                display: none !important;
            }
        }
        @include breakpoint(md) {
            float        : none;
            margin-bottom: 18px;
        }

        &.open {
            > a {
                color     : $text-color;
                background: none;

                &.active,
                &.active-trail,
                &:focus,
                &:hover {
                    color     : $link-hover-color;
                    background: none;
                }
            }
        }
    }
}

// Sub-menus
// --------------------------------------------------
.dropdown-submenu {
    position: relative;

    .dropdown-menu {
        top        : 0;
        left       : 100%;
        margin-top : -6px;
        margin-left: -1px;
    }
}

// navbar
.navbar-brand {
    padding  : 0;
    float    : none;
    height   : auto;
    font-size: 21px;
}

.navbar-header {
    float: none;
    // height: 12px;
}

.navbar-default {
    background-color: transparent;
    border          : none;
    margin          : 0;
    min-height      : 140px;
    width           : auto;

    .navbar-toggle {
        margin-right: 0;
        float       : none;
        padding     : 5px 0;
        position    : absolute;
        right       : 0;
        top         : 20px;
        z-index     : $zindex-navbar;
    }

    .navbar-nav {
        border: none;
    }

    .navbar-collapse {
        padding: 0;
        border : none;

        .navbar-right {
            display: none;
        }
    }
}

.tabs {
    bottom       : 0;
    margin-bottom: 0 !important;
    position     : fixed;
    width        : 100%;
    z-index      : 1000;
}
