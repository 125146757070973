#main-menu,
.menu-block-2 {
    li {
        a {
            text-transform: uppercase;
            font-size     : 10px;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

#main-menu {
    margin-top: 60px;
    text-align: center;
    @media screen and (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
        margin    : 100px 0 30px;
        text-align: center;
    }
    @media screen and (min-width: $screen-sm) {
        margin-top: 0;
        float     : right;
    }

    .menu-link-galleries- {
        display: none;
    }

    li {
        a {
            text-transform: uppercase;
            @media screen and (min-width: $screen-sm) {
                line-height: 60px;
            }
        }
    }

    .last a {
	@media screen and (min-width: $screen-sm) {
            padding-right: 0;
	}
    }
}

.block-menu-block {
    float         : left;
    vertical-align: top;
    width         : auto;
    margin-right  : 50px;
    @media screen and (max-width: $screen-md) {
        width: 100%;

        ul {
            width    : 100%;
            display  : flex;
            flex     : 1 1 100px;
            flex-wrap: wrap;
        }
    }

    a {
        padding-top   : 2px !important;
        padding-bottom: 2px !important;
    }

    .active {
        color: $gray-light !important;
    }

    h4 {
        display: none;
    }
}
